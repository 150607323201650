import React from 'react';
import SearchBar from '../components/SearchBar';
import { DashboardContext } from '../contexts/Dashboard';
import { QuestionListContainer } from '../components/QuestionList/container';
import Header from '../components/PageHeader';
import { DashboardTopCoursesContainer } from '../components/DashboardTopCourses/container';
import { useGetAllQuestions } from '../hooks/useGetQuestions';
import { useGetTopics } from '../hooks/useGetTopics';

const Dashboard: React.FC = () => {
  const { data: questions } = useGetAllQuestions();
  const { data: topics } = useGetTopics();

  return (
    <DashboardContext.Provider value={{ questions: questions, topics: topics }}>
      <Header />
      <div className="min-h-screen bg-base-200 p-8">
        <DashboardTopCoursesContainer />

        <SearchBar />
        <QuestionListContainer />
      </div>
    </DashboardContext.Provider>
  );
};

export default Dashboard;
