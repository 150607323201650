import React, { useEffect, useState } from 'react';
import { ModelManagement } from '.';
import { deleteUserModel, uploadNewModel } from '../../services/modelsService';
import { useAuth } from '../../auth/authContext';
import {
  ISemanticModelMetadata,
  ISemanticModelUploadPayload,
} from '../../models/SemanticModel';
import { useGetUserSemanticModels } from '../../hooks/useGetUserSemanticModels';

export const ModelManagementContainer: React.FC = () => {
  const [dragActive, setDragActive] = useState(false);
  const initialModelDetails: ISemanticModelUploadPayload = {
    fileName: '',
    file: null as unknown as File,
    modelName: '',
    modelDescription: '',
    public: false,
  };
  const [newModelDetails, setNewModelDetails] =
    useState<ISemanticModelUploadPayload>(initialModelDetails);
  const { userModels } = useGetUserSemanticModels();
  const { user } = useAuth();

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files[0];
    if (file && file.name.endsWith('.pbix')) {
      setNewModelDetails({
        ...newModelDetails,
        fileName: file.name,
        file: file,
      });
      console.log(`Dropped new file: ${file.name}`);
    }
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file && file.name.endsWith('.pbix')) {
      setNewModelDetails({
        ...newModelDetails,
        fileName: file.name,
        file: file,
      });
      console.log(`New file changed to file: ${file.name}`);
    }
  };

  const handleSubmit = async () => {
    if (
      newModelDetails.modelName.trim() &&
      newModelDetails.fileName.trim() &&
      newModelDetails.file
    ) {
      if (user) {
        const authToken = await user.getIdToken();
        const status = await uploadNewModel(
          authToken,
          newModelDetails as ISemanticModelUploadPayload,
        );
        // TODO: Emit toast message
        if (status) {
          setNewModelDetails(initialModelDetails);
          // Trigger model refresh - pass down a refresh function or ... zustand?
        }
      } else {
        console.error('Upload failed. User must be authenticated.');
      }
    }
  };

  const handleModelDelete = async (modelId: string) => {
    const deletionEligible = userModels.find(
      (model) => model.modelId === modelId && model.modelType == 'user_owned',
    );
    if (deletionEligible) {
      await deleteUserModel(modelId);
    }
  };

  return (
    <ModelManagement
      handleDragEnter={handleDragEnter}
      handleDragLeave={handleDragLeave}
      handleFileChange={handleFileChange}
      handleDrop={handleDrop}
      handleSubmit={handleSubmit}
      dragActive={dragActive}
      newModelDetails={newModelDetails}
      setNewModelDetails={setNewModelDetails}
      setDragActive={setDragActive}
      uploadedModels={
        userModels &&
        userModels.map(({ modelId, modelName, publicModel, modelType }) => ({
          modelId,
          modelName,
          publicModel,
          modelType,
        }))
      }
      handleModelDelete={handleModelDelete}
    />
  );
};
