import { getAllCourses } from '../services/courseService';
import { useQuery } from '@tanstack/react-query';
import { DEFAULT_CACHE_DATA_STALENESS } from '../services/utils';

export const useGetAllCourses = () => {
  const { isPending, error, data } = useQuery({
    staleTime: DEFAULT_CACHE_DATA_STALENESS,
    queryKey: ['getAllCourses'],
    queryFn: () => getAllCourses(),
  });

  return { isPending, error, data: data ?? [] };
};
