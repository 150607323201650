import { useAuth } from '../auth/authContext';
import { getAllUserModels } from '../services/modelsService';
import { useQuery } from '@tanstack/react-query';
import { DEFAULT_CACHE_DATA_STALENESS } from '../services/utils';
import { UseCustomQueryOptions } from '../models/UseQueryOptions';

export const useGetUserSemanticModels = (options?: UseCustomQueryOptions) => {
  const { user } = useAuth();
  const { isPending, error, data, refetch } = useQuery({
    ...options,
    staleTime: DEFAULT_CACHE_DATA_STALENESS,
    queryKey: ['getUserSemanticModels', user],
    queryFn: () =>
      user
        ? user.getIdToken().then((authToken) => getAllUserModels(authToken))
        : getAllUserModels(null),
  });

  return { userModels: data ?? [], refetch, isPending, error };
};
