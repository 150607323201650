import { ISemanticModelMetadata } from '../models/SemanticModel';

export const userModelsMock: ISemanticModelMetadata[] = [
  {
    modelId: 'sales_data',
    modelName: 'Sales Data Model',
    modelDescription: 'A model containing sales data for the organization',
    modelType: 'user_owned',
    tables: [
      {
        name: 'Customers',
        columns: [
          {
            name: 'customer_id',
            dataType: 'string',
            type: 'Data',
            isKey: false,
          },
          {
            name: 'customer_name',
            dataType: 'string',
            type: 'Data',
            isKey: false,
          },
          {
            name: 'customer_address',
            dataType: 'string',
            type: 'Data',
            isKey: false,
          },
        ],
        relationships: [
          {
            id: 1,
            fromTable: 'Customers',
            fromColumn: 'customer_id',
            fromCardinality: 'yes',
            toCardinality: 'no',
            toTable: 'Orders',
            toColumn: 'order_id',
          },
          {
            fromCardinality: 'yes',
            toCardinality: 'no',
            id: 2,
            fromTable: 'Orders',
            fromColumn: 'order_id',

            toTable: 'Products',
            toColumn: 'product_id',
          },
          {
            fromCardinality: 'yes',
            toCardinality: 'no',
            id: 3,
            fromTable: 'Products',
            fromColumn: 'product_id',

            toTable: 'Inventory',
            toColumn: 'inventory_id',
          },
        ],
      },
      {
        name: 'Orders',
        columns: [
          { name: 'order_id', dataType: 'string', type: 'Data', isKey: false },
          { name: 'order_date', dataType: 'date', type: 'Data', isKey: false },
          {
            name: 'order_total',
            dataType: 'number',
            type: 'Data',
            isKey: false,
          },
        ],
        relationships: [],
      },
      {
        name: 'Products',
        columns: [
          {
            name: 'product_id',
            dataType: 'string',
            type: 'Data',
            isKey: false,
          },
          {
            name: 'product_name',
            dataType: 'string',
            type: 'Data',
            isKey: false,
          },
          {
            name: 'product_price',
            dataType: 'number',
            type: 'Data',
            isKey: false,
          },
        ],
        relationships: [],
      },
    ],
  },
  {
    modelId: 'inventory_data',
    modelName: 'Inventory Data Model',
    modelDescription: 'A model containing inventory data for the organization',
    tables: [
      {
        name: 'Products',
        columns: [
          {
            name: 'product_id',
            dataType: 'Integer',
            type: 'Data',
            isKey: false,
          },
          {
            name: 'product_name',
            dataType: 'string',
            type: 'Data',
            isKey: false,
          },
          {
            name: 'product_quantity',
            dataType: 'number',
            type: 'Data',
            isKey: false,
          },
        ],
        relationships: [
          {
            fromTable: 'Customers',
            fromColumn: 'customer_id',
            toTable: 'Orders',
            toColumn: 'order_id',
            fromCardinality: 'yes',
            toCardinality: 'no',
            id: 1,
          },
          {
            fromTable: 'Orders',
            fromColumn: 'order_id',

            toTable: 'Products',
            toColumn: 'product_id',
            fromCardinality: 'yes',
            toCardinality: 'no',
            id: 2,
          },
          {
            fromTable: 'Products',
            fromColumn: 'product_id',

            toTable: 'Inventory',
            toColumn: 'inventory_id',
            fromCardinality: 'yes',
            toCardinality: 'no',
            id: 3,
          },
        ],
      },
      {
        name: 'Locations',
        columns: [
          {
            name: 'location_id',
            dataType: 'string',
            type: 'Data',
            isKey: false,
          },
          {
            name: 'location_name',
            dataType: 'string',
            type: 'Data',
            isKey: false,
          },
          {
            name: 'location_address',
            dataType: 'string',
            type: 'Data',
            isKey: false,
          },
        ],
        relationships: [],
      },
    ],
  },
];

export const newSingleModelMock: ISemanticModelMetadata = {
  modelId: 'sales_data',
  modelName: 'Sales Data Model',
  modelDescription: 'A model containing sales data for the organization',
  modelType: 'user_owned',
  tables: [
    {
      name: 'Customers',
      columns: [
        { name: 'customer_id', dataType: 'string', type: 'Data', isKey: false },
        {
          name: 'customer_name',
          dataType: 'string',
          type: 'Data',
          isKey: false,
        },
        {
          name: 'customer_address',
          dataType: 'string',
          type: 'Data',
          isKey: false,
        },
      ],
      relationships: [
        {
          fromTable: 'Customers',
          fromColumn: 'customer_id',
          fromCardinality: 'yes',
          toCardinality: 'no',
          id: 1,
          toTable: 'Orders',
          toColumn: 'order_id',
        },
        {
          fromTable: 'Orders',
          fromColumn: 'order_id',
          fromCardinality: 'yes',
          toCardinality: 'no',
          id: 2,
          toTable: 'Products',
          toColumn: 'product_id',
        },
        {
          fromTable: 'Products',
          fromColumn: 'product_id',
          fromCardinality: 'yes',
          toCardinality: 'no',
          id: 3,
          toTable: 'Inventory',
          toColumn: 'inventory_id',
        },
      ],
    },
    {
      name: 'Orders',
      columns: [
        { name: 'order_id', dataType: 'string', type: 'Data', isKey: false },
        { name: 'order_date', dataType: 'date', type: 'Data', isKey: false },
        { name: 'order_total', dataType: 'number', type: 'Data', isKey: false },
      ],
      relationships: [],
    },
    {
      name: 'Products',
      columns: [
        { name: 'product_id', dataType: 'string', type: 'Data', isKey: false },
        {
          name: 'product_name',
          dataType: 'string',
          type: 'Data',
          isKey: false,
        },
        {
          name: 'product_price',
          dataType: 'number',
          type: 'Data',
          isKey: false,
        },
      ],
      relationships: [],
    },
  ],
};
