import { ICourseInfo } from '../models/Courses';
import { questionsListMock } from './questions';

export const getAllCoursesMock: ICourseInfo[] = [
  {
    id: 'course1',
    title: 'Introduction to DAX Programming',
    description:
      'Master the art of data analysis with DAX! This comprehensive course will equip you with the skills to effectively manipulate and analyze data within Power BI.',
    full_description:
      "Master the art of data analysis with DAX! This comprehensive course will equip you with the skills to effectively manipulate and analyze data within Power BI. You'll learn how to write powerful DAX expressions to calculate key metrics, create dynamic visualizations, and gain valuable insights from your data.",
    questions: questionsListMock,
    public: true,
    instructors: [],
  },
  {
    id: 'course2',
    title: 'Dax Structures and Algorithms',
    description:
      'Master the art of data analysis with DAX! This comprehensive course will equip you with the skills to effectively manipulate and analyze data within Power BI. ',
    full_description:
      "Master the art of data analysis with DAX! This comprehensive course will equip you with the skills to effectively manipulate and analyze data within Power BI. You'll learn how to write powerful DAX expressions to calculate key metrics, create dynamic visualizations, and gain valuable insights from your data.",
    questions: questionsListMock,
    public: true,
    instructors: [],
  },
  {
    id: 'course3',
    title: 'Dax Model Management Systems',
    description:
      "You'll learn how to write powerful DAX expressions to calculate key metrics, create dynamic visualizations, and gain valuable insights from your data.",
    full_description:
      "Master the art of data analysis with DAX! This comprehensive course will equip you with the skills to effectively manipulate and analyze data within Power BI. You'll learn how to write powerful DAX expressions to calculate key metrics, create dynamic visualizations, and gain valuable insights from your data.",
    questions: questionsListMock,
    public: true,
    instructors: [],
  },
  {
    id: 'course4',
    title: 'Streamlit Web Development',
    description:
      "Master the art of data analysis with DAX! This comprehensive course will equip you with the skills to effectively manipulate and analyze data within Power BI. You'll learn how to write powerful DAX expressions to calculate key metrics, create dynamic visualizations, and gain valuable insights from your data.",
    full_description:
      "Master the art of data analysis with DAX! This comprehensive course will equip you with the skills to effectively manipulate and analyze data within Power BI. You'll learn how to write powerful DAX expressions to calculate key metrics, create dynamic visualizations, and gain valuable insights from your data.",
    questions: questionsListMock,
    public: true,
    instructors: [],
  },
  {
    id: 'course5',
    title: 'Mobile App Development',
    description:
      "Master the art of data analysis with DAX! This comprehensive course will equip you with the skills to effectively manipulate and analyze data within Power BI. You'll learn how to write powerful DAX expressions to calculate key metrics, create dynamic visualizations, and gain valuable insights from your data.",
    full_description:
      "Master the art of data analysis with DAX! This comprehensive course will equip you with the skills to effectively manipulate and analyze data within Power BI. You'll learn how to write powerful DAX expressions to calculate key metrics, create dynamic visualizations, and gain valuable insights from your data.",
    questions: questionsListMock,
    public: true,
    instructors: [],
  },
];

export const getCourseDetailsMock: ICourseInfo = {
  id: 'course1',
  title: 'Introduction to DAX Programming',
  description:
    'Master the art of data analysis with DAX! This comprehensive course will equip you with the skills to effectively manipulate and analyze data within Power BI.',
  full_description:
    "Master the art of data analysis with DAX! This comprehensive course will equip you with the skills to effectively manipulate and analyze data within Power BI. You'll learn how to write powerful DAX expressions to calculate key metrics, create dynamic visualizations, and gain valuable insights from your data.",
  questions: questionsListMock,
  public: true,
  instructors: [],
};
