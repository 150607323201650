import {
  getAllUserCodePlaygroundsMocks,
  newPlaygroundMock,
} from '../mocks/codePlaygounds';
import { isMockServer } from '../mocks/utils';
import { IPlaygroundExecutionResult } from '../models/ExecutionResult';
import {
  IPlaygroundRequestPayload,
  PlaygroundDetails,
} from '../models/Playground';
import { BASE_API_URL } from './utils';

export const getAllUserCodePlaygrounds = async (
  authToken?: string | null,
): Promise<PlaygroundDetails[]> => {
  if (isMockServer()) {
    return Promise.resolve(getAllUserCodePlaygroundsMocks);
  }

  try {
    const headers = new Headers();
    if (authToken) {
      headers.append('Authorization', authToken);
    }
    const response = await fetch(
      `${BASE_API_URL}/api/v1/playground/playground-manager`,
      {
        method: 'GET',
        headers: headers,
      },
    );
    if (!response.ok) {
      console.error('Failed to fetch playgrounds');
      return [];
    }
    const respData = await response.json();
    const playgrounds: PlaygroundDetails[] = respData;
    return playgrounds;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getCodePlaygroundInfo = async (
  playgroundId: number,
  authToken?: string | null,
): Promise<PlaygroundDetails | null> => {
  console.log('returning mock for', playgroundId);
  if (isMockServer()) {
    return Promise.resolve(newPlaygroundMock);
  }

  try {
    const headers = new Headers();
    if (authToken) {
      headers.append('Authorization', authToken);
    }
    const response = await fetch(
      `${BASE_API_URL}/api/v1/playground/playground-manager`,
      {
        method: 'GET',
        headers: headers,
      },
    );
    if (!response.ok) {
      console.error('Failed to fetch playgrounds');
      return null;
    }
    const respData = await response.json();
    const playgrounds: PlaygroundDetails[] = respData;
    return (
      playgrounds.find((playground) => playground.id === playgroundId) ?? null
    );
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const deleteCodePlayground = async (
  authToken: string,
  playGroundId: number,
): Promise<void> => {
  try {
    const headers = new Headers({
      Authorization: authToken,
    });
    const response = await fetch(
      `${BASE_API_URL}/api/v1/playground/playground-manager/${playGroundId}`,
      {
        method: 'DELETE',
        headers: headers,
      },
    );
    if (!response.ok) {
      console.error('Failed to delete playground');
      return;
    }
    console.log('Playground deleted successfully');
  } catch (e) {
    console.error(e);
  }
};

export const createNewPlayground = async (
  authToken: string,
  playgroundDetails: IPlaygroundRequestPayload,
): Promise<PlaygroundDetails> => {
  if (isMockServer()) {
    return Promise.resolve(newPlaygroundMock);
  }
  try {
    const headers = new Headers({
      Authorization: authToken,
      'Content-Type': 'application/json',
    });
    const response = await fetch(
      `${BASE_API_URL}/api/v1/playground/playground-manager`,
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(playgroundDetails),
      },
    );
    if (!response.ok) {
      console.error('Failed to create playground');
      return {} as PlaygroundDetails;
    }
    const playground: PlaygroundDetails = await response.json();
    return playground;
  } catch (e) {
    console.error(e);
    return {} as PlaygroundDetails;
  }
};

export const runPlaygroundCode = async (
  authToken: string,
  playgroundId: number,
  code: string,
): Promise<IPlaygroundExecutionResult> => {
  try {
    const headers = new Headers({
      Authorization: authToken,
      'Content-Type': 'application/json',
    });
    const payload = {
      code: code,
      playground_id: playgroundId,
    };
    const response = await fetch(`${BASE_API_URL}/api/v1/playground/run`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      console.error('Failed to run playground code');
      return {} as IPlaygroundExecutionResult;
    }
    const result: IPlaygroundExecutionResult = await response.json();
    return result;
  } catch (e) {
    console.error(e);
    return {} as IPlaygroundExecutionResult;
  }
};
