import { getAllCoursesMock, getCourseDetailsMock } from '../mocks/courses';
import { isMockServer } from '../mocks/utils';
import { ICourseInfo } from '../models/Courses';
import { BASE_API_URL } from './utils';

export const getAllCourses = async (): Promise<ICourseInfo[]> => {
  if (isMockServer()) {
    return Promise.resolve(getAllCoursesMock);
  }
  try {
    const response = await fetch(`${BASE_API_URL}/api/v1/assessment/courses`, {
      method: 'GET',
    });
    if (!response.ok) {
      console.error('Failed to fetch courses');
      return [];
    }
    const response_data = await response.json();
    const courses: ICourseInfo[] = response_data.results;
    return courses;
  } catch (e) {
    console.error(e);
    return [];
  }
};

// TODO
export const getCourseDetails = async (
  courseId: number,
): Promise<ICourseInfo> => {
  if (isMockServer()) {
    return Promise.resolve(getCourseDetailsMock);
  }
  try {
    const response = await fetch(
      `${BASE_API_URL}/api/v1/assessment/courses/${courseId}`,
      {
        method: 'GET',
      },
    );
    if (!response.ok) {
      console.error('Failed to fetch course details');
      return {} as ICourseInfo;
    }
    const course: ICourseInfo = await response.json();
    return course;
  } catch (e) {
    console.error(e);
    return {} as ICourseInfo;
  }
};
