import { Monaco } from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { createContext, useContext } from 'react';
import { IExecResult } from '../models/ExecutionResult';
import { IQuestion } from '../models/Question';
import { ICourseInfo } from '../models/Courses';

export interface ISolverContext {
  question: IQuestion | null;
  associatedCourseInfo: ICourseInfo | undefined;
  codeInput: string;
  setCodeInput: React.Dispatch<React.SetStateAction<string>>;
  results: IExecResult | null;
  editorRef: React.MutableRefObject<editor.IStandaloneCodeEditor | null>;
  monacoRef: React.MutableRefObject<Monaco | null>;
  onTablePreviewClick: (tableName: string) => Promise<void>;
}

export const SolverContext = createContext<ISolverContext | null>(null);
export const useSolverContext = () => useContext(SolverContext)!;
