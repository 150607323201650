import { useAuth } from '../auth/authContext';
import { UseCustomQueryOptions } from '../models/UseQueryOptions';
import { getPlans } from '../services/accountsService';
import { useQuery } from '@tanstack/react-query';
import { DEFAULT_CACHE_DATA_STALENESS } from '../services/utils';

export const useGetPlans = (options?: UseCustomQueryOptions) => {
  const { user } = useAuth();
  const { isPending, error, data, refetch } = useQuery({
    ...options,
    staleTime: DEFAULT_CACHE_DATA_STALENESS,
    queryKey: ['getPlans', user],
    queryFn: () => getPlans(),
  });

  return { isPending, error, data, refetch };
};
