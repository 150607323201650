import React from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

interface IAuthComponent {
  isAuthenticated: boolean;
  handleSignin: () => Promise<void>;
  handleSignout: () => Promise<void>;
}
const AuthComponent: React.FC<IAuthComponent> = ({
  isAuthenticated,
  handleSignin,
  handleSignout,
}) => {
  return (
    <button
      className="flex items-center justify-center"
      onClick={isAuthenticated ? handleSignout : handleSignin}
    >
      {isAuthenticated ? (
        <svg
          className="w-10 h-10"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="-151.14 -151.14 806.09 806.09"
          xmlSpace="preserve"
          fill="#000000"
          transform="rotate(0)"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0">
            <path
              transform="translate(-151.14, -151.14), scale(25.1903125)"
              d="M16,27.620798418564455C18.116742696992034,27.218421481064517,20.280931568376072,27.645750625728493,22.317473087459803,26.942184362929403C24.64783639930789,26.137111158716635,27.01540125177271,25.165360411001735,28.592684411213867,23.270389734634335C30.28541784382067,21.236715439377722,31.79131149439137,18.620526362355946,31.425225169530936,16C31.06040546839575,13.388540408615253,28.475091456755326,11.77642539082843,26.712954872819772,9.814872620361195C25.277933243943295,8.217453511385928,23.69641748701476,6.907493923347209,22.03498759067485,5.547094869903486C20.05916810087924,3.9292697846471123,18.55275317053067,0.9746231757860648,16.000000000000004,1.0429745699678143C13.429657065440974,1.1117969399213494,12.062124046618045,4.147671398579353,10.148760130895038,5.865355259437532C8.61976049687769,7.237983839410118,7.0913142643041285,8.500699055559954,5.860194406739245,10.145780511200423C4.495173000848757,11.96978764388074,2.6628667287451937,13.723911003786375,2.5643561205693644,15.999999999999998C2.4653582682343296,18.287346779408498,4.476180046655527,20.03419988319665,5.340458359650221,22.15428923549396C6.3628892990073,24.662329661128922,5.714884065151228,28.40416898524376,8.124891618000603,29.640087832734473C10.578047262119025,30.898134220518415,13.291572243179843,28.13565025567143,16,27.620798418564455"
              fill="#c8e1ea"
              strokeWidth="0"
            ></path>
          </g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {' '}
            <g transform="translate(1 1)">
              {' '}
              <path
                style={{ fill: '#ECF4F7' }}
                d="M327.704,251.075v51.2v128c0,18.773-15.36,34.133-34.133,34.133h-153.6V37.741h153.6 c18.773,0,34.133,15.36,34.133,34.133V251.075z"
              ></path>{' '}
              <path
                style={{ fill: '#80D6FA' }}
                d="M139.971,37.741v426.667v8.533c0,18.773-13.653,29.867-29.867,23.893l-81.067-32.427 c-19.627-6.827-25.6-15.36-25.6-34.133v-358.4c0-18.773,6.827-27.307,25.6-34.133l81.067-32.427 c16.213-5.973,29.867,5.12,29.867,23.893V37.741z"
              ></path>{' '}
            </g>{' '}
            <path
              style={{ fill: '#51565F' }}
              d="M119.637,503.808c-3.413,0-6.827-0.853-10.24-1.707L28.33,469.675 c-20.48-7.68-28.16-17.92-28.16-38.4v-358.4c0-20.48,7.68-30.72,28.16-38.4l81.067-32.427c8.533-3.413,17.067-2.56,23.893,2.56 c7.68,5.12,11.947,14.507,11.947,25.6v443.733c0,11.093-4.267,20.48-11.947,25.6C129.877,502.101,124.757,503.808,119.637,503.808z M119.637,8.875c-2.56,0-5.12,0.853-7.68,1.707L30.891,43.008c-17.92,6.827-23.04,13.653-23.04,29.867v358.4 c0,17.067,5.12,23.893,23.04,29.867l81.067,32.427c5.973,2.56,11.947,1.707,16.213-0.853c5.12-3.413,7.68-10.24,7.68-18.773V30.208 c0-8.533-2.56-15.36-7.68-18.773C126.464,9.728,123.051,8.875,119.637,8.875z M294.571,469.675H175.104 c-2.56,0-4.267-1.707-4.267-4.267c0-2.56,1.707-4.267,4.267-4.267h119.467c16.213,0,29.867-13.653,29.867-29.867v-128 c0-2.56,1.707-4.267,4.267-4.267s4.267,1.707,4.267,4.267v128C332.971,452.608,315.904,469.675,294.571,469.675z M414.037,341.675 c-0.853,0-2.56,0-3.413-0.853c-1.707-1.707-1.707-4.267,0-5.973l77.653-77.653H192.171c-2.56,0-4.267-1.707-4.267-4.267 c0-2.56,1.707-4.267,4.267-4.267h296.96l-77.653-77.653c-1.707-1.707-1.707-4.267,0-5.973c1.707-1.707,4.267-1.707,5.973,0 l85.333,85.333c0,0,0.853,0.853,0.853,1.707l0,0l0,0c0,0.853,0,0.853,0,1.707l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0 c0,0.853,0,0.853,0,1.707l0,0l0,0c0,0.853-0.853,0.853-0.853,0.853l0,0l0,0l0,0l0,0l0,0l-85.333,85.333 C416.597,340.821,414.891,341.675,414.037,341.675z M106.837,281.941c-2.56,0-4.267-1.707-4.267-4.267v-51.2 c0-2.56,1.707-4.267,4.267-4.267s4.267,1.707,4.267,4.267v51.2C111.104,280.234,109.397,281.941,106.837,281.941z M328.704,205.141 c-2.56,0-4.267-1.707-4.267-4.267v-128c0-16.213-13.653-29.867-29.867-29.867h-120.32c-2.56,0-4.267-1.707-4.267-4.267 c0-2.56,1.707-4.267,4.267-4.267h120.32c21.333,0,38.4,17.067,38.4,38.4v128C332.971,203.434,331.264,205.141,328.704,205.141z"
            ></path>{' '}
          </g>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          className="w-10 h-10"
          viewBox="0 0 48 48"
        >
          <path
            fill="#FFC107"
            d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
          ></path>
          <path
            fill="#FF3D00"
            d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
          ></path>
          <path
            fill="#4CAF50"
            d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
          ></path>
          <path
            fill="#1976D2"
            d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
          ></path>
        </svg>
      )}
    </button>
  );
};

export default AuthComponent;
