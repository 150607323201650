import { ICourseQuestion } from '../models/Courses';
import { IQuestion } from '../models/Question';
import { newSingleModelMock } from './userModels';

export const questionsListMock = [
  {
    id: 1,
    title: 'Limit the number of results in query results',
    description:
      'When inspecting tables, it is good to have an idea of the general overview of the table, but retrieving all data may be time consuming and also costly.\r\n\r\nWe can limit results by using TOPN(). See docs.\r\n\r\nWrite a query to get the top 10 currencies in the "DimCurrency" model',
    difficulty: 'easy',
    created_at: '2024-09-03T06:12:28.279911Z',
    updated_at: '2024-09-08T05:22:02.694040Z',
    courseId: 'course1',
    dax_query_template:
      '================Fill dax expression \n ${daxExpression} \n ===================',
    tabular_model: newSingleModelMock,
  },
  {
    id: 2,
    title: 'Order the results',
    description:
      'When working with tables, it is sometimes necessary to order by date, or time, or alphabetically.\r\n\r\nThis helps with keeping results deterministic.\r\n\r\nDocs for ORDER\r\n\r\nTable schema is:\r\nCurrencyKey | CurrencyName | CurrencyAlternateKey\r\n\r\nOn the "DimCurrency" model, order by CurrencyAlternateKey',
    difficulty: 'easy',
    created_at: '2024-09-03T06:13:27.638994Z',
    updated_at: '2024-09-08T05:38:13.362626Z',
    courseId: 'course1',
    dax_query_template:
      '================Fill dax expression \n ${daxExpression} \n ===================',
    tabular_model: newSingleModelMock,
  },
  {
    id: 3,
    title: 'Order the results v2',
    description:
      'When working with tables, it is sometimes necessary to order by date, or time, or alphabetically.\r\n\r\nThis helps with keeping results deterministic.\r\n\r\nDocs for ORDER\r\n\r\nTable schema is:\r\nCurrencyKey | CurrencyName | CurrencyAlternateKey\r\n\r\nOn the "DimCurrency" model, order by CurrencyAlternateKey',
    difficulty: 'medium',
    created_at: '2024-09-03T06:13:27.638994Z',
    updated_at: '2024-09-08T05:38:13.362626Z',
    courseId: 'course1',
    dax_query_template:
      '================Fill dax expression \n ${daxExpression} \n ===================',
    tabular_model: newSingleModelMock,
  },
  {
    id: 4,
    title: 'Commando the command',
    description:
      'When working with tables, it is sometimes necessary to order by date, or time, or alphabetically.\r\n\r\nThis helps with keeping results deterministic.\r\n\r\nDocs for ORDER\r\n\r\nTable schema is:\r\nCurrencyKey | CurrencyName | CurrencyAlternateKey\r\n\r\nOn the "DimCurrency" model, order by CurrencyAlternateKey',
    difficulty: 'hard',
    created_at: '2024-09-03T06:13:27.638994Z',
    updated_at: '2024-09-08T05:38:13.362626Z',
    courseId: 'course1',
    dax_query_template:
      '================Fill dax expression \n ${daxExpression} \n ===================',
    tabular_model: newSingleModelMock,
  },
];

export const fetchQuestionMock: (id: number) => ICourseQuestion = (
  id: number,
) => {
  return {
    id,
    title: `Question ${id}`,
    description: `This is the ${id} question.`,
    difficulty: 'easy',
    courseId: 'course1',
    created_at: '2024-09-03T06:12:28.279911Z',
    updated_at: '2024-09-08T05:22:02.694040Z',
    dax_query_template:
      '================Fill dax expression \n ${daxExpression} \n ===================',
    tabular_model: newSingleModelMock,
    numberOfAttempts: 2,
    hasSuccessfulAttempt: true,
  };
};
export const questions = [
  {
    id: 1,
    title: 'Limit the number of results in query results',
    description:
      'When inspecting tables, it is good to have an idea of the general overview of the table, but retrieving all data may be time consuming and also costly.\r\n\r\nWe can limit results by using TOPN(). See docs.\r\n\r\nWrite a query to get the top 10 currencies in the "DimCurrency" model',
    difficulty: 'easy',
    created_at: '2024-09-03T06:12:28.279911Z',
    updated_at: '2024-09-08T05:22:02.694040Z',
    courseId: 'course1',
    dax_query_template:
      '================Fill dax expression \n ${daxExpression} \n ===================',
  },
  {
    id: 2,
    title: 'Order the results',
    description:
      'When working with tables, it is sometimes necessary to order by date, or time, or alphabetically.\r\n\r\nThis helps with keeping results deterministic.\r\n\r\nDocs for ORDER\r\n\r\nTable schema is:\r\nCurrencyKey | CurrencyName | CurrencyAlternateKey\r\n\r\nOn the "DimCurrency" model, order by CurrencyAlternateKey',
    difficulty: 'easy',
    created_at: '2024-09-03T06:13:27.638994Z',
    updated_at: '2024-09-08T05:38:13.362626Z',
    courseId: 'course1',
    dax_query_template:
      '================Fill dax expression \n ${daxExpression} \n ===================',
  },
  {
    id: 3,
    title: 'Order the results v2',
    description:
      'When working with tables, it is sometimes necessary to order by date, or time, or alphabetically.\r\n\r\nThis helps with keeping results deterministic.\r\n\r\nDocs for ORDER\r\n\r\nTable schema is:\r\nCurrencyKey | CurrencyName | CurrencyAlternateKey\r\n\r\nOn the "DimCurrency" model, order by CurrencyAlternateKey',
    difficulty: 'medium',
    created_at: '2024-09-03T06:13:27.638994Z',
    updated_at: '2024-09-08T05:38:13.362626Z',
    courseId: 'course1',
    dax_query_template:
      '================Fill dax expression \n ${daxExpression} \n ===================',
  },
  {
    id: 4,
    title: 'Commando the command',
    description:
      'When working with tables, it is sometimes necessary to order by date, or time, or alphabetically.\r\n\r\nThis helps with keeping results deterministic.\r\n\r\nDocs for ORDER\r\n\r\nTable schema is:\r\nCurrencyKey | CurrencyName | CurrencyAlternateKey\r\n\r\nOn the "DimCurrency" model, order by CurrencyAlternateKey',
    difficulty: 'hard',
    created_at: '2024-09-03T06:13:27.638994Z',
    updated_at: '2024-09-08T05:38:13.362626Z',
    courseId: 'course1',
    dax_query_template:
      '================Fill dax expression \n ${daxExpression} \n ===================',
  },
];
