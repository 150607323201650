import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../auth/authContext';
import { fetchTopics } from '../services/questionService';
import { DEFAULT_CACHE_DATA_STALENESS } from '../services/utils';
import { UseCustomQueryOptions } from '../models/UseQueryOptions';

export const useGetTopics = (options?: UseCustomQueryOptions) => {
  const { user } = useAuth();
  const { isPending, error, data, refetch } = useQuery({
    ...options,
    staleTime: DEFAULT_CACHE_DATA_STALENESS,
    queryKey: ['getAllTopics', user],
    queryFn: () =>
      user
        ? user.getIdToken().then((authToken) => fetchTopics(authToken))
        : fetchTopics(null),
  });

  return { isPending, error, data: data ?? [], refetch };
};
