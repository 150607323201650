import { fetchQuestion } from '../services/questionService';
import { useAuth } from '../auth/authContext';
import { useQuery } from '@tanstack/react-query';

export const useGetQuestionInfo = (questionId?: string) => {
  const { user } = useAuth();
  const dataFetch = async () => {
    if (questionId) {
      const authToken = user ? await user.getIdToken() : null;
      const questionItem = await fetchQuestion(questionId, authToken);
      return questionItem;
    } else {
      console.error('No question ID provided');
    }
  };

  const { isPending, error, data, refetch } = useQuery({
    queryKey: ['fetchQuestion', questionId, user],
    queryFn: () => dataFetch(),
  });

  return { isPending, error, data, refetch };
};
