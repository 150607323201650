import { useAuth } from '../auth/authContext';
import { useQuery } from '@tanstack/react-query';
import { getAllUserCodePlaygrounds } from '../services/playgroundService';
import { DEFAULT_CACHE_DATA_STALENESS } from '../services/utils';
import { UseCustomQueryOptions } from '../models/UseQueryOptions';

export const useGetUserPlaygrounds = (options?: UseCustomQueryOptions) => {
  const { user } = useAuth();
  const { isPending, error, data, refetch } = useQuery({
    ...options,
    staleTime: DEFAULT_CACHE_DATA_STALENESS,
    queryKey: ['getAllUserPlaygrounds', user],
    queryFn: () =>
      user
        ? user
            .getIdToken()
            .then((authToken) => getAllUserCodePlaygrounds(authToken))
        : getAllUserCodePlaygrounds(null),
  });

  return {
    isPending,
    error,
    userCodePlaygrounds: data ?? [],
    refreshPlaygrounds: refetch,
  };
};
