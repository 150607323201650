import React from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import AuthComponent from '.';
import { auth, googleAuthProvider } from '../../config/firebaseConfig';
import { useAuth } from '../../auth/authContext';

const AuthComponentContainer: React.FC = () => {
  const { isAuthenticated } = useAuth();

  const handleSignin = async () => {
    try {
      await signInWithPopup(auth, googleAuthProvider);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSignout = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AuthComponent
      isAuthenticated={isAuthenticated}
      handleSignin={handleSignin}
      handleSignout={handleSignout}
    />
  );
};

export default AuthComponentContainer;
