import { IPlanList, SubscriptionTier } from '../../models/Plan';
import { EmbeddedCheckoutButton } from '../Stripe';
import React, { useState } from 'react';
import { useAuth } from '../../auth/authContext';
interface IPricingPlanProps {
  planList?: IPlanList | null;
}

export const PricingPlans: React.FC<IPricingPlanProps> = ({ planList }) => {
  const [isAnnual, setIsAnnual] = useState(false);
  const { daxSolverUser } = useAuth();
  const plans = planList?.plans;

  // Wrapper to get stripe price in cents and convert to dollars
  // Handles cases where plan list is null too.
  const getPlanPriceAmount = (plan: string) => {
    if (!plans) return;

    if (isAnnual) {
      switch (plan) {
        case 'individual':
          return `$${plans.annual.individual.unit_amount / 100}/yr`;
        case 'professional':
          return `$${plans.annual.professional.unit_amount / 100}/yr`;
        default:
          return 'N/A';
      }
    } else {
      switch (plan) {
        case 'individual':
          return `$${plans.monthly.individual.unit_amount / 100}/mo`;
        case 'professional':
          return `$${plans.monthly.professional.unit_amount / 100}/mo`;
        default:
          return 'N/A';
      }
    }
  };

  const getPlanPriceId = (plan: string) => {
    if (!plans) return;

    if (isAnnual) {
      switch (plan) {
        case 'individual':
          return plans.annual.individual.id;
        case 'professional':
          return plans.annual.professional.id;
        default:
          return 'N/A';
      }
    } else {
      switch (plan) {
        case 'individual':
          return plans.monthly.individual.id;
        case 'professional':
          return plans.monthly.professional.id;
        default:
          return 'N/A';
      }
    }
  };

  return (
    <div className="min-h-screen bg-base-200 p-10">
      <h1 className="text-4xl font-bold text-center mb-10 prose">Pricing</h1>

      {/* Toggle between Monthly and Annual */}
      <div className="flex justify-center mb-8">
        <button
          className={`px-4 py-2 rounded-l-[30px] ${!isAnnual ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => setIsAnnual(false)}
        >
          Monthly
        </button>
        <button
          className={`px-4 py-2 rounded-r-[30px] ${isAnnual ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => setIsAnnual(true)}
        >
          Annual
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
        {/* Free Plan */}
        <div className="border p-6 rounded-lg shadow-md flex flex-col justify-between">
          <div className='prose'>
          <h2 className="text-xl font-bold text-center mb-4">Starter</h2>
          <p className="text-3xl font-bold text-center mb-4">Free</p>
          <p className="text-center">Perfect for beginners</p>
          <ul className="list-disc mt-4 pl-6">
            <li>Run 6 DAX queries per day</li>
            <li>Access a limited set of problems</li>
            <li>Apply to all free jobs</li>
            <li>No access to premium jobs</li>
          </ul>

          </div>
          
          <div className="card-actions justify-center mt-8">
            {/* TODO: Show "current plan" if user is authenticated and on the free plan.
            Otherwise show "Signup to start" with redirect to signup page. */}
            <button className="btn btn-outline btn-secondary">
              {daxSolverUser?.subscription_plan == SubscriptionTier.free
                ? 'Current Plan'
                : 'Free forever'}
            </button>
          </div>
        </div>

        {/* Individual Plan */}
        <div className="border p-6 rounded-lg shadow-md flex flex-col justify-between">
          <div className='prose'>
          <h2 className="text-xl font-bold text-center mb-4">Individual</h2>
          <p className="text-3xl font-bold text-center mb-4">
            {getPlanPriceAmount('individual')}
          </p>
          <p className="text-center">For individual users</p>
          <ul className="list-disc mt-4 pl-6">
            <li>Run 100 DAX queries per day</li>
            <li>Access all problems and courses</li>
            <li>Apply to all jobs with priority notification</li>
            <li>Ad-free experience</li>
          </ul>
          </div>
          <div className="card-actions justify-center mt-8">
            {daxSolverUser?.subscription_plan == SubscriptionTier.individual ? (
              <button className="btn btn-outline btn-secondary">
                Current Plan
              </button>
            ) : (
              <EmbeddedCheckoutButton priceId={getPlanPriceId('individual')} />
            )}
          </div>
        </div>

        {/* Professional Plan */}
        <div className="border p-6 rounded-lg shadow-md flex flex-col justify-between">
          <div className='prose'>
          <h2 className="text-xl font-bold text-center mb-4">Professional</h2>
          <p className="text-3xl font-bold text-center mb-4">
            {getPlanPriceAmount('professional')}
          </p>
          <p className="text-center">For power users</p>
          <ul className="list-disc mt-4 pl-6">
            <li>Everything in Personal, plus:</li>
            <li>Upload your own models</li>
            <li>Generate fake data against your models</li>
            <li>Unlimited access to job board</li>
            <li>Advanced analytics tools</li>
          </ul>
          </div>
          <div className="card-actions justify-center mt-8">
            {daxSolverUser?.subscription_plan ==
            SubscriptionTier.professional ? (
              <button className="btn btn-outline btn-secondary">
                Current Plan
              </button>
            ) : (
              <EmbeddedCheckoutButton
                priceId={getPlanPriceId('professional')}
              />
            )}
          </div>
        </div>

        {/* Enterprise Plan */}
        <div className="border p-6 rounded-lg shadow-md flex flex-col justify-between">
          <div className='prose'>
          <h2 className="text-xl font-bold text-center mb-4">Enterprise</h2>
          <p className="text-3xl font-bold text-center mb-4">Custom</p>
          <p className="text-center">For teams and enterprises</p>
          <ul className="list-disc mt-4 pl-6">
            <li>Everything in Professional, plus:</li>
            <li>Priority customer support</li>
            <li>Custom data models and analytics</li>
            <li>Advanced data governance features</li>
            <li>Team collaboration tools</li>
          </ul>
          </div>
          <div className="card-actions justify-center mt-8">
            <a href="mailto:admin@daxsolver.com" className="btn btn-secondary">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
