import { PlaygroundDetails } from '../models/Playground';
import { newSingleModelMock } from './userModels';

export const getAllUserCodePlaygroundsMocks: PlaygroundDetails[] = [
  {
    id: 1,
    name: 'Playground 1',
    public: false,
    description: 'This is an example playground for personal reasons',
    slug_uuid: 'fsvadvsavb',
    query_content: 'SELECT TOPN from Table tablename',
    created_at: '2024-09-03T06:12:28.279911Z',
    updated_at: '2024-09-08T05:22:02.694040Z',
    tabular_model: newSingleModelMock,
  },
  {
    id: 2,
    name: 'Playground 2',
    public: false,
    slug_uuid: 'fsvadvsavb',
    description: 'This is an example playground for personal reasons',
    query_content: 'SELECT TOPN from Table tablename',
    created_at: '2024-09-03T06:12:28.279911Z',
    updated_at: '2024-09-08T05:22:02.694040Z',
    tabular_model: newSingleModelMock,
  },
  {
    id: 3,
    name: 'Playground 3',
    slug_uuid: 'fsvadvsavb',
    description: 'This is an example playground for personal reasons',
    query_content: 'SELECT TOPN from Table tablename',
    created_at: '2024-09-03T06:12:28.279911Z',
    updated_at: '2024-09-08T05:22:02.694040Z',
    tabular_model: newSingleModelMock,
    public: true,
  },
  {
    id: 4,
    name: 'Playground 4',
    slug_uuid: 'fsvadvsavb',
    description: 'This is an example playground for personal reasons',
    query_content: 'SELECT TOPN from Table tablename',
    created_at: '2024-09-03T06:12:28.279911Z',
    updated_at: '2024-09-08T05:22:02.694040Z',
    tabular_model: newSingleModelMock,
    public: false,
  },
  {
    id: 5,
    name: 'Playground 5',
    slug_uuid: 'fsvadvsavb',
    description: 'This is an example playground for personal reasons',
    query_content: 'SELECT TOPN from Table tablename',
    created_at: '2024-09-03T06:12:28.279911Z',
    updated_at: '2024-09-08T05:22:02.694040Z',
    tabular_model: newSingleModelMock,
    public: false,
  },
];

export const newPlaygroundMock: PlaygroundDetails = {
  id: 10,
  name: 'Playground 2',
  slug_uuid: 'fsvadvsavb',
  description: 'This is an example playground for personal reasons',
  query_content: 'SELECT TOPN from Table tablename',
  created_at: '2024-09-03T06:12:28.279911Z',
  updated_at: '2024-09-08T05:22:02.694040Z',
  tabular_model: newSingleModelMock,
  public: true,
};
