import React from 'react';
import { DataTablesView } from '.';
import { SemanticModelTables } from '../../models/SemanticModel';

interface IDataTablesContainerViewProps {
  tables: SemanticModelTables[];
  onTablePreview: (tableName: string) => Promise<void>;
}
export const DataTablesViewContainer: React.FC<
  IDataTablesContainerViewProps
> = ({ tables, onTablePreview }) => {
  return <DataTablesView tables={tables} onTablePreview={onTablePreview} />;
};
