import { useNavigate } from 'react-router-dom';
import { DashboardTopCourses } from '.';
import { useGetAllCourses } from '../../hooks/useGetCourses';

export const DashboardTopCoursesContainer = () => {
  const { data: courses } = useGetAllCourses();
  const navigate = useNavigate();
  const onSeeMoreClick = () => navigate('/courses');
  // if (courses.length = 0){
  //   return null
  // }
  return (
    <DashboardTopCourses courses={courses} onSeeMoreClick={onSeeMoreClick} />
  );
};
