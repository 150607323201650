import React from 'react';
import { SemanticModelTables } from '../../models/SemanticModel';

interface IDataTablesViewProps {
  tables: SemanticModelTables[];
  onTablePreview: (tableName: string) => Promise<void>;
}
export const DataTablesView: React.FC<IDataTablesViewProps> = ({
  tables,
  onTablePreview,
}) => {
  return (
    <div className="flex flex-col space-y-4 p-4">
      {tables.map((table, index) => (
        <div
          key={index}
          className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box"
        >
          <input type="checkbox" />
          <div className="collapse-title text-lg font-medium text-primary flex justify-between items-center">
            <span>{table.name}</span>
            <button
              className="btn btn-sm bg-gray-500 hover:bg-gray-600 text-white z-10"
              onClick={(e) => {
                e.stopPropagation();
                onTablePreview(table.name);
              }}
            >
              Preview
            </button>
          </div>
          <div className="collapse-content text-secondary">
            <table className="table w-full">
              <thead>
                <tr>
                  <th>Column</th>
                  <th>Data Type</th>
                </tr>
              </thead>
              <tbody>
                {table.columns.map((column, colIndex) => (
                  <tr key={colIndex}>
                    <td>{column.name}</td>
                    <td>{column.dataType}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};
