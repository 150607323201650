import QuestionList from '.';
import { useDashboardContext } from '../../contexts/Dashboard';
import daxData from '../SolverCodeEditorPanel/dax-data.json';

export const QuestionListContainer: React.FC = () => {
  const { questions, topics } = useDashboardContext();

  return (
    <QuestionList items={questions} title="Question List" topics={topics} />
  );
};
