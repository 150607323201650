import { getCourseDetails } from '../services/courseService';
import { useQuery } from '@tanstack/react-query';

export const useGetCourseInfo = (courseId?: number) => {
  const { isPending, error, data, refetch } = useQuery({
    queryKey: ['getCourseDetails', courseId],
    queryFn: () => {return courseId ? getCourseDetails(courseId) : undefined},
    enabled: !!courseId,
  });

  return { courseInfo: data, loadData: refetch, isPending, error };
};
