import { useState, useRef, useEffect } from 'react';

export const ThemeSwitcher = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const menuActivateRef = useRef<HTMLDivElement | null>(null);

  // Close the menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        !menuRef.current?.contains(event.target) &&
        !menuActivateRef.current?.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const themes = ['light', 'dark', 'forest', 'cupcake']; // Add your themes here

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullScreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setIsFullScreen(false);
    }
  };

  const switchTheme = (theme: string) => {
    document.documentElement.setAttribute('data-theme', theme);
  };

  return (
    <>
      <div
        className="rounded-lg text-white"
        ref={menuActivateRef}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <button
          className="flex items-center justify-center tooltip tooltip-right"
          data-tip="Theme Switcher"
        >
          <svg
            viewBox="0 0 1024 1024"
            className="icon w-10 h-10"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M407.2 992c-57.6 0-104.8-47.2-104.8-104.8V299.2H512v588c0 57.6-47.2 104.8-104.8 104.8z"
                fill="#FFFFFF"
              ></path>
              <path
                d="M407.2 1000c-62.4 0-112.8-50.4-112.8-112.8V299.2c0-4.8 3.2-8 8-8H512c4.8 0 8 3.2 8 8v588c0 62.4-50.4 112.8-112.8 112.8zM309.6 307.2v580c0 53.6 43.2 96.8 96.8 96.8S504 940.8 504 887.2V307.2H309.6z"
                fill="#6A576D"
              ></path>
              <path
                d="M401.6 939.2c-27.2-2.4-47.2-27.2-47.2-54.4V530.4c0-28.8 23.2-52.8 52.8-52.8 28.8 0 52.8 23.2 52.8 52.8v356.8c-0.8 30.4-27.2 55.2-58.4 52zM301.6 427.2c31.2 16 67.2 25.6 104.8 25.6s73.6-8.8 104.8-25.6v-128H301.6v128z"
                fill="#0D1014"
              ></path>
              <path
                d="M496 32v152.8c0 49.6-40 89.6-89.6 89.6s-89.6-40-89.6-89.6V32c-71.2 33.6-120.8 105.6-120.8 189.6C196.8 337.6 291.2 432 407.2 432s210.4-94.4 210.4-210.4C616.8 137.6 567.2 65.6 496 32z"
                fill="#FFFFFF"
              ></path>
              <path
                d="M748 508.8H696V182.4c0-15.2-6.4-30.4-16.8-40.8-2.4-2.4-3.2-6.4-3.2-9.6L696 32h52.8L768 131.2c0.8 3.2 0 7.2-3.2 9.6-11.2 11.2-16.8 25.6-16.8 40.8v327.2z"
                fill="#E8E8E6"
              ></path>
              <path d="M696 488h52.8v20.8H696z" fill="#0D1014"></path>
              <path
                d="M827.2 582.4v-73.6H616.8v73.6c17.6 0 31.2 14.4 31.2 31.2s-14.4 31.2-31.2 31.2v241.6c0 57.6 47.2 104.8 104.8 104.8 57.6 0 104.8-47.2 104.8-104.8V645.6c-17.6 0-31.2-14.4-31.2-31.2s14.4-32 32-32z"
                fill="#EC7BB0"
              ></path>
              <path
                d="M648.8 613.6c0 17.6-13.6 31.2-31.2 31.2h209.6c-17.6 0-31.2-14.4-31.2-31.2s13.6-31.2 31.2-31.2H616.8c17.6 0 32 14.4 32 31.2z"
                fill="#EC7BB0"
              ></path>
              <path
                d="M648.8 613.6c0 17.6-13.6 31.2-31.2 31.2h209.6c-17.6 0-31.2-14.4-31.2-31.2s13.6-31.2 31.2-31.2H616.8c17.6 0 32 14.4 32 31.2zM764.8 116L748 32H696l-16.8 84zM643.2 861.6c0 14.4 12 26.4 26.4 26.4 14.4 0 26.4-12 26.4-26.4v-216h-52.8v216zM643.2 508.8H696v73.6h-52.8zM748 861.6c0 14.4 12 26.4 26.4 26.4s26.4-12 26.4-26.4v-216h-52.8v216zM748 508.8h52.8v73.6h-52.8z"
                fill="#0D1014"
              ></path>
              <path
                d="M407.2 947.2h-6.4c-30.4-3.2-54.4-30.4-54.4-62.4V529.6c0-33.6 27.2-60.8 60.8-60.8s60.8 27.2 60.8 60.8v356.8c-0.8 33.6-28 60.8-60.8 60.8z m0-461.6c-24.8 0-44.8 20-44.8 44.8v354.4c0 24 17.6 44.8 40 46.4 26.4 2.4 48.8-18.4 48.8-44V529.6c0-24-20-44-44-44zM407.2 440c-120 0-218.4-97.6-218.4-218.4 0-84 49.6-161.6 125.6-196.8 2.4-0.8 5.6-0.8 8 0.8 2.4 1.6 4 4 4 6.4v152.8c0 44.8 36.8 81.6 81.6 81.6s81.6-36.8 81.6-81.6V32c0-2.4 1.6-5.6 4-6.4 2.4-1.6 5.6-1.6 8-0.8C576 60.8 624.8 138.4 624.8 221.6 624.8 342.4 527.2 440 407.2 440zM309.6 44.8c-64 35.2-104.8 103.2-104.8 176.8 0 112 90.4 202.4 202.4 202.4s202.4-90.4 202.4-202.4C608.8 148.8 568 80 504 44.8v140c0 53.6-44 97.6-97.6 97.6-53.6 0-97.6-44-97.6-97.6V44.8zM722.4 1000c-62.4 0-112.8-50.4-112.8-112.8V645.6c0-4.8 3.2-8 8-8 12.8 0 23.2-10.4 23.2-23.2s-10.4-23.2-23.2-23.2c-4.8 0-8-3.2-8-8v-73.6c0-4.8 3.2-8 8-8h210.4c4.8 0 8 3.2 8 8v73.6c0 4-3.2 8-8 8-12.8 0-23.2 10.4-23.2 23.2 0 12.8 10.4 23.2 23.2 23.2 4 0 8 4 8 8v241.6c-0.8 62.4-52 112.8-113.6 112.8zM624.8 652.8v234.4c0 53.6 43.2 96.8 96.8 96.8s96.8-43.2 96.8-96.8V652.8c-17.6-4-31.2-20-31.2-38.4 0-19.2 13.6-34.4 31.2-38.4v-58.4H624.8V576c17.6 4 31.2 20 31.2 38.4 0.8 18.4-12.8 34.4-31.2 38.4z"
                fill="#6A576D"
              ></path>
              <path
                d="M748 516.8H696c-4.8 0-8-3.2-8-8V182.4c0-13.6-5.6-25.6-14.4-35.2-4-4-6.4-10.4-4.8-16.8L688 30.4c0.8-4 4-6.4 8-6.4h52.8c4 0 7.2 2.4 8 6.4L776 129.6c1.6 6.4-0.8 12-4.8 16.8-9.6 9.6-14.4 22.4-14.4 35.2v327.2c-0.8 4.8-4 8-8.8 8z m-44-16h36.8V182.4c0-17.6 7.2-34.4 19.2-46.4 0.8-0.8 0.8-1.6 0.8-2.4L741.6 40h-39.2l-18.4 92.8c0 0.8 0 1.6 0.8 2.4 12.8 12.8 19.2 28.8 19.2 46.4v319.2zM827.2 653.6H617.6c-4.8 0-8-3.2-8-8s3.2-8 8-8c12.8 0 23.2-10.4 23.2-23.2 0-12.8-10.4-23.2-23.2-23.2-4.8 0-8-3.2-8-8s3.2-8 8-8h210.4c4.8 0 8 3.2 8 8 0 4-3.2 8-8 8-12.8 0-23.2 10.4-23.2 23.2 0 12.8 10.4 23.2 23.2 23.2 4.8 0 8 4 8 8-0.8 4-4.8 8-8.8 8z m-178.4-16h147.2c-4.8-6.4-8-14.4-8-23.2 0-8.8 3.2-16.8 8-23.2H648.8c4.8 6.4 8 14.4 8 23.2 0 8-3.2 16.8-8 23.2z"
                fill="#6A576D"
              ></path>
              <path
                d="M764.8 124H679.2c-2.4 0-4.8-0.8-6.4-3.2-1.6-1.6-2.4-4-1.6-6.4L688 30.4c0.8-4 4-6.4 8-6.4h52.8c4 0 7.2 2.4 8 6.4l16.8 84c0.8 2.4 0 4.8-1.6 6.4-2.4 2.4-4.8 3.2-7.2 3.2z m-76-16h66.4L741.6 40h-39.2l-13.6 68z"
                fill="#6A576D"
              ></path>
            </g>
          </svg>
        </button>
      </div>
      {isMenuOpen && (
        <div
          className="absolute rounded-lg shadow-lg bg-white text-black z-50 flex flex-col hover:bg-white"
          ref={menuRef}
          style={{
            left: '5rem',
            bottom: '0vh',
            minWidth: '300px',
            maxHeight: '60vh',
            overflowY: 'auto',
            padding: '1rem',
          }}
        >
          <div className="form-control mb-4">
            <label className="label cursor-pointer">
              <span className="label-text mr-4">Focus mode </span>
              <input
                type="checkbox"
                className="toggle toggle-primary"
                checked={isFullScreen}
                onChange={toggleFullScreen}
              />
            </label>
          </div>

          <h2> Switch themes </h2>
          <div className="grid grid-cols-2 gap-4 mt-4">
            {themes.map((theme) => (
              <div
                onClick={() => switchTheme(theme)}
                className="border-base-content/20 hover:border-base-content/40 overflow-hidden rounded-lg border outline outline-2 outline-offset-2 outline-transparent"
                data-act-class="!outline-base-content"
                data-set-theme={theme}
              >
                <div
                  className="bg-base-100 text-base-content w-full cursor-pointer font-sans"
                  data-theme={theme}
                >
                  <div className="grid grid-cols-5 grid-rows-3">
                    <div className="bg-base-200 col-start-1 row-span-2 row-start-1"></div>{' '}
                    <div className="bg-base-300 col-start-1 row-start-3"></div>{' '}
                    <div className="bg-base-100 col-span-4 col-start-2 row-span-3 row-start-1 flex flex-col gap-1 p-2">
                      <div className="font-bold">{theme}</div>{' '}
                      <div className="flex flex-wrap gap-1">
                        <div className="bg-primary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                          <div className="text-primary-content text-sm font-bold">
                            A
                          </div>
                        </div>{' '}
                        <div className="bg-secondary flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                          <div className="text-secondary-content text-sm font-bold">
                            A
                          </div>
                        </div>{' '}
                        <div className="bg-accent flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                          <div className="text-accent-content text-sm font-bold">
                            A
                          </div>
                        </div>{' '}
                        <div className="bg-neutral flex aspect-square w-5 items-center justify-center rounded lg:w-6">
                          <div className="text-neutral-content text-sm font-bold">
                            A
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
