import { IQuestionFilters } from '../models/Question';
import { fetchQuestions } from '../services/questionService';
import { useAuth } from '../auth/authContext';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { DEFAULT_CACHE_DATA_STALENESS } from '../services/utils';
import { UseCustomQueryOptions } from '../models/UseQueryOptions';

export const useGetAllQuestions = (options?: UseCustomQueryOptions) => {
  const { user } = useAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const topicFilter = queryParams.getAll('topic');
  const difficultyFilter = queryParams.getAll('difficulty');
  const nameFilter = queryParams.getAll('name');
  const filters: IQuestionFilters = {
    topic: topicFilter,
    difficulty: difficultyFilter,
    name: nameFilter,
  };

  const { isPending, error, data, refetch } = useQuery({
    ...options,
    staleTime: DEFAULT_CACHE_DATA_STALENESS,
    queryKey: ['getAllQuestions', user, filters],
    queryFn: () =>
      user
        ? user
            .getIdToken()
            .then((authToken) => fetchQuestions(authToken, filters))
        : fetchQuestions(null),
  });

  return { isPending, error, data: data ?? [], refetch };
};
